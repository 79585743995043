.notie-container {
  text-align: center;
  z-index: 2147483647;
  box-sizing: border-box;
  -o-box-shadow: 0px 0px 5px 0px #00000080;
  -ms-box-shadow: 0px 0px 5px 0px #00000080;
  width: 100%;
  height: auto;
  font-size: 1.6rem;
  position: fixed;
  left: 0;
  box-shadow: 0 0 5px #00000080;
}

@media screen and (max-width: 900px) {
  .notie-container {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  .notie-container {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 400px) {
  .notie-container {
    font-size: 1rem;
  }
}

.notie-background-success {
  background-color: #57bf57;
}

.notie-background-warning {
  background-color: #d6a14d;
}

.notie-background-error {
  background-color: #e1715b;
}

.notie-background-info {
  background-color: #4d82d6;
}

.notie-background-neutral {
  background-color: #a0a0a0;
}

.notie-background-overlay {
  background-color: #fff;
}

.notie-textbox {
  color: #fff;
  padding: 20px;
}

.notie-textbox-inner {
  max-width: 900px;
  margin: 0 auto;
}

.notie-overlay {
  opacity: 0;
  z-index: 2147483646;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.notie-button {
  color: #fff;
  cursor: pointer;
  padding: 10px;
}

.notie-element {
  color: #fff;
  padding: 10px;
}

.notie-element-half {
  box-sizing: border-box;
  width: 50%;
  display: inline-block;
}

.notie-element-third {
  box-sizing: border-box;
  width: 33.3333%;
  display: inline-block;
}

.notie-alert {
  cursor: pointer;
}

.notie-input-field {
  font-family: inherit;
  font-size: inherit;
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  border: 0;
  outline: 0;
  width: 100%;
  padding: 10px;
}

.notie-select-choice-repeated {
  box-sizing: border-box;
  border-bottom: 1px solid #fff3;
}

.notie-date-selector-inner {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  max-width: 900px;
  margin: 0 auto;
}

.notie-date-selector-inner [contenteditable], .notie-date-selector-inner [contenteditable]:focus {
  outline: 0 solid #0000;
}

.notie-date-selector-up {
  transform: rotate(180deg);
}

/*# sourceMappingURL=index.4bfe0eea.css.map */
